import React, { FC } from 'react';
import { BrandsPagePropertiesType } from '../../../models/brands-page-properties-type';
import ProductImage from '../../atoms/product-image/product-image';

const BrandsTop: FC<BrandsPagePropertiesType> = ({
  logoAltText,
  logo,
}: BrandsPagePropertiesType) => (
  <>
    <div className="absolute bg-grey-20 inset-x-0 justify-center lg:-mt-14 pt-8 lg:pt-16 w-screen">
      <div className="bg-white h-48 -mb-6 mx-auto p-8 relative rounded-lg shadow-md w-48">
        <ProductImage alt={logoAltText} src={logo?.url} />
      </div>
    </div>

    <div className="pb-60 lg:pb-56" />
  </>
);

export default BrandsTop;
