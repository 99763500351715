import { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';
import Error from 'next/error';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import BrandsDetailPage from '../../components/templates/brands-detail-page/brands-detail-page';
import ContentPage from '../../components/templates/content-page/content-page';
import getContentPage from '../../helpers/get-content-page';
import getErrorResponse from '../../helpers/get-error-response';
import useLogoutAfterInactivity from '../../hooks/use-logout-after-inactivity/use-logout-after-inactivity';
import { CategoryStructureType } from '../../models/category-structure-type';
import ContentPageDocumentTypeEnum from '../../models/content-page-document-type-enum';
import { ContentPageType } from '../../models/content-page-type';
import { ProductsResultType } from '../../models/products-result-type';
import SearchRequestSortTypeEnum from '../../models/search-request-sort-type-enum';
import withCache from '../../services/cache/with-cache';
import getCategoryStructure from '../../services/content-api/endpoints/category/structure';
import HttpException from '../../services/exceptions/http-exception';
import searchFull from '../../services/search-api/endpoints/search/full';
import { getStockForProductsResult } from '../../services/search-api/endpoints/search/stock';
import mapProductsStockByInternalRef from '../../helpers/map-products-stock-by-internal-ref';
import { ProductsStockMapByRefType } from '../../models/products-stock-map-by-ref-type';

interface Props {
  readonly page: ContentPageType;
  readonly productsResult: ProductsResultType;
  readonly productsStockByRef: ProductsStockMapByRefType;
  readonly statusCode: number;
}

const BrandsPage: NextPage<Props> = ({
  page,
  productsResult,
  productsStockByRef,
  statusCode,
}: Props) => {
  useLogoutAfterInactivity(page);

  if (statusCode) {
    return <Error statusCode={statusCode} />;
  }

  if (page.documentType === ContentPageDocumentTypeEnum.BrandsDetailPage) {
    return (
      <BrandsDetailPage
        page={page}
        productsResult={productsResult}
        productsStockByRef={productsStockByRef}
      />
    );
  }

  return <ContentPage {...page} />;
};

const getBrandDetail = async (
  categoryStructure: CategoryStructureType,
  context: GetServerSidePropsContext<ParsedUrlQuery>,
  page: ContentPageType
): Promise<ProductsResultType | null> => {
  if (page.documentType !== ContentPageDocumentTypeEnum.BrandsDetailPage) {
    return null;
  }

  return searchFull({
    brand: page.name,
    catalogId: categoryStructure.catalogId,
    filters: {
      categoryFilter: context.query.category as string,
      facetFilters: context.query.facets
        ? JSON.parse(context.query.facets as string)
        : null,
    },
    page: context.query.page ? Number(context.query.page) : 1,
    pageSize: context.query.pageSize ? Number(context.query.pageSize) : 24,
    sortType: context.query.sortType
      ? Number(context.query.sortType)
      : SearchRequestSortTypeEnum.Relevance,
  });
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>
) => {
  try {
    const categoryStructure = await withCache('categoryStructure', () =>
      getCategoryStructure()
    );

    const page = await getContentPage(
      context.resolvedUrl,
      Boolean(context.query.preview ?? false),
      context.query.token ?? ''
    );

    if (!page?.regions?.main) {
      throw new HttpException(404);
    }

    const productsResult = await getBrandDetail(
      categoryStructure,
      context,
      page
    );

    const productsStockResult = await getStockForProductsResult(productsResult);
    const productsStockByRef =
      mapProductsStockByInternalRef(productsStockResult);

    return {
      props: {
        page,
        productsResult,
        productsStockByRef,
      },
    };
  } catch (error) {
    if (error.response?.status === 404 || error.status === 404)
      return { notFound: true };

    return getErrorResponse(error);
  }
};

export default BrandsPage;
